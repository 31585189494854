import React, { useState } from "react";

const AboutUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission
    alert("Thank you for contacting us. We will get back to you soon!");
  };

  return (
    <main className="container mx-auto px-6 py-12">
      <section className="mb-12">
        <h1 className="text-4xl font-bold text-gray mb-4">Vedang Consulting</h1>
        <p className="text-lg text-gray-700 mb-8">
          At Vedang Consulting, we are dedicated to transforming your business with cutting-edge technology and strategic insights.
          With years of experience in software engineering, cloud architecture, and AI-driven solutions, we empower organizations to
          achieve their full potential in today's fast-paced digital world.
        </p>
      </section>
      <section className="mb-12">
        <h2 className="text-3xl font-bold text-primary mb-4">Meet Our Team</h2>
        <div className="mb-8">
          <h3 className="text-2xl font-semibold text-primary">
            Subodh Ramugade
          </h3>
          <p className="text-lg text-secondary">
            Founder & Principal Consultant
            <br />
          </p>
          <br />
          <p className="text-lg text-secondary">
            Subodh Ramugade is a seasoned expert in software engineering, cloud
            architecture, and AI-driven solutions. With extensive experience
            across industries like finance, e-commerce, and marketing, he has
            led the development and delivery of numerous mission-critical
            products and services. His core strengths include:
          </p>
          <br />
          <p>
            <ul className="text-lg text-secondary list-disc list-inside">
              <li>Cloud Architecture & Financial Systems</li>
              <li>AI & Machine Learning</li>
              <li>E-Commerce & Marketing Solutions</li>
              <li>Project Management & Leadership</li>
            </ul>
          </p>
          <br />
          <p className="text-lg text-secondary">
            Subodh has worked with several top-tier firms, driving innovation
            and growth through technology. He holds a strong presence in the
            tech community and is passionate about using AI to solve complex
            business challenges.
          </p>
          <div className="flex items-center mt-4">
            <a
              href="https://www.linkedin.com/in/subodhramugade/"
              className="text-blue-500 underline"
            >
              Subodh's LinkedIn Profile
            </a>
          </div>
          <div className="flex items-center mt-4">
            <a
              href="http://www.subodhramugade.com"
              className="text-blue-500 underline"
            >
              Subodh's Website
            </a>
          </div>
        </div>
        <div className="mb-8">
          <h3 className="text-2xl font-semibold text-primary">
            Seema Ramugade
          </h3>
          <p className="text-lg text-secondary">Co-Founder & Operations Lead</p>
          <br />
          <p className="text-lg text-secondary">
            Seema Ramugade brings a wealth of experience in operations and project management. With a focus on streamlining processes and managing large teams, she ensures that all projects at Vedang Consulting are delivered on time and within budget. Seema has been instrumental in establishing the operational framework that supports the firm's growth. Her skills includes
          </p>
          <br />
          <p>
            <ul className="text-lg text-secondary list-disc list-inside">
              <li>Operations Management</li>
              <li>Team Leadership</li>
              <li>Project Coordination</li>
              <li>Process Improvement</li>
            </ul>
          </p>

          <br />
          <a
            href="https://www.linkedin.com/in/seemaramugade/"
            className="text-blue-500 underline"
          >
            Seema's LinkedIn Profile
          </a>
        </div>
      </section>

      <section className="mb-12">
        <h2 className="text-3xl font-bold text-primary mb-4">Our Offices</h2>
        <div className="row">
          <div className="mb-8 column" >
            <h3 className="text-2xl font-semibold text-primary">
              Burnaby, Canada
            </h3>
            <p className="text-lg text-secondary">
              Midori, 6638 Dunbanle Ave, Burnaby, Canada.
            </p>
            <iframe title="burnabyMap" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5245.532285256293!2d-122.99501372367749!3d49.22329547495792!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5486765b26c716ad%3A0x5f26c6438eaed299!2s6638%20Dunblane%20Ave%2C%20Burnaby%2C%20BC%20V5H%200G8!5e1!3m2!1sen!2sca!4v1723782729211!5m2!1sen!2sca" width="450" height="450" allowfullscreen="" loading="lazy" >
            </iframe>
          </div>
          <div className="mb-8 column">
            <h3 className="text-2xl font-semibold text-primary">Thane, India</h3>
            <p className="text-lg text-secondary">
              Athena Rustomjee, Thane West, India.
            </p>
            <iframe title="thaneMap" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3792.1456397152374!2d72.98317077515767!3d19.211928747691218!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b935d4000001%3A0x422e679fb93acbae!2sRustomjee%20Athena!5e1!3m2!1sen!2sca!4v1723782935375!5m2!1sen!2sca" width="450" height="450" allowfullscreen="" loading="lazy" ></iframe>
          </div>
        </div>
      </section>

      <section>
        <h2 className="text-3xl font-bold text-primary mb-4">Contact Us</h2>
        <form
          onSubmit={handleSubmit}
          className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
        >
          <div className="mb-4">
            <label htmlFor="name" className="block text-sm font-bold mb-2">
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="email" className="block text-sm font-bold mb-2">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="message" className="block text-sm font-bold mb-2">
              Message
            </label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>
          <div className="flex items-center justify-between">
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Contact Us
            </button>
          </div>
        </form>
      </section>
    </main>
  );
};

export default AboutUs;
