import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <header className="bg-white shadow">
      <div className="container mx-auto px-6 py-3">
        <div className="flex items-center justify-between">
          <div className="text-2xl font-bold text-gray-800">
            <Link to="/">Vedang Consulting</Link>
          </div>
          <nav className="flex items-center space-x-4">
            <Link to="/" className="text-gray-800 hover:text-gray-600">Home</Link>
            <Link to="/about" className="text-gray-800 hover:text-gray-600">About Us</Link>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;

//<Link to="/research" className="text-gray-800 hover:text-gray-600">Research</Link>