import React from 'react';

const Home = () => {
  return (
    <main className="container mx-auto px-6 py-12">
      <section className="text-center">
        <h1 className="text-4xl font-bold text-gray mb-4">Welcome to Vedang Consulting</h1>
        <p className="text-lg text-gray-700 mb-8">
          At Vedang Consulting, we are dedicated to transforming your business with cutting-edge technology and strategic insights. 
          With years of experience in software engineering, cloud architecture, and AI-driven solutions, we empower organizations to 
          achieve their full potential in today's fast-paced digital world.
        </p>
      </section>
      <section className="mb-12">
        <h2 className="text-3xl font-bold text-gray mb-4">Our Mission</h2>
        <p className="text-lg text-gray-700">
          Our mission is to deliver high-performance, scalable, and innovative solutions that drive growth and operational efficiency. 
          We specialize in providing customized consulting services that align with your business goals, ensuring that you stay ahead of 
          the competition.
        </p>
      </section>
      <section className="mb-12">
        <h2 className="text-3xl font-bold text-gray mb-4">Our Expertise</h2>
        <ul className="text-lg text-gray-700 list-disc list-inside">
          <li>Cloud Architecture & Financial Systems: Designing and implementing robust cloud solutions tailored to your business needs.</li>
          <li>E-Commerce & Marketing: Developing and managing mission-critical products and services that drive significant revenue growth.</li>
          <li>AI & Machine Learning: Leveraging advanced AI and machine learning techniques to optimize data-driven solutions and deliver impactful results.</li>
        </ul>
      </section>
      <section className="mb-12">
        <h2 className="text-3xl font-bold text-gray mb-4">Who We Are</h2>
        <p className="text-lg text-gray-700">
          Vedang Consulting is led by a team of seasoned software engineering professionals with hands-on experience in leading large-scale projects. 
          Our leadership has successfully managed tech teams, driven innovation through system architecture, and delivered measurable results in 
          various industries, including finance, e-commerce, and marketing.
        </p>
      </section>
      <section>
        <h2 className="text-3xl font-bold text-gray mb-4">Customers & Partners</h2>
        <p className="text-lg text-gray-700">
          Our clients trust us to deliver exceptional solutions that meet their unique needs. We partner with businesses of all sizes, 
          from startups to large enterprises, helping them navigate the complexities of technology adoption and digital transformation.
        </p>
      </section>
    </main>
  );
};

export default Home;
