import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white">
      <div className="container mx-auto px-6 py-3">
        <div className="text-center">
          <p>© 2024 Vedang Consulting. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
