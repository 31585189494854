import React from 'react';

const Research = () => {
  return (
    <main>
      <section className="bg-gray-100 py-20">
        <div className="container mx-auto">
          <h1 className="text-4xl font-bold">Blogs</h1>
          <div className="mt-6 space-y-4">
            <article className="p-4 bg-white rounded shadow">
              <h2 className="text-2xl font-bold">Blog Post 1</h2>
              <p className="mt-2 text-gray-600">A brief description of the first blog post.</p>
            </article>
            <article className="p-4 bg-white rounded shadow">
              <h2 className="text-2xl font-bold">Blog Post 2</h2>
              <p className="mt-2 text-gray-600">A brief description of the second blog post.</p>
            </article>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Research;
